<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div>
        <vs-button
            v-if="$can('create', 'notification-settings')"
            size="small" color="success" type="border" icon-pack="feather" icon="icon-file-plus"
            @click="createNotificationSettings()"
        >
          Crear Destinatarios
        </vs-button>
      </div>
    </template>

    <div v-if="$can('list', 'notification-settings')" class="vx-row">
      <div class="vx-col md:w-4/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="notificationSettings" :hoverFlat="true">
          <template slot="thead">
            <vs-th/>
            <vs-th sort-key="entry_type">Modulo</vs-th>
            <vs-th sort-key="recipient">Destinatario</vs-th>
            <vs-th sort-key="unix_created_at">Fecha creacion</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><span>{{ index + 1 }}</span></vs-td>
              <vs-td><p class="font-medium">{{ name }}</p></vs-td>
              <vs-td><p class="font-medium">{{ record.recipient }}</p></vs-td>
              <vs-td><p class="font-medium">{{ dateFromTimestamp(record.unix_created_at) }}</p></vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                        :disabled="!($can('retrieve', 'notification-settings') || $can('update', 'notification-settings'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                        @click="updateNotificationSettings(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                        :disabled="!$can('delete', 'notification-settings')"
                        radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                        @click="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import Notifier from "./../../utils/notification";

  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
  let notifier = new Notifier();

  export default {
    data() {
      return {
        entryTypeList: {
          "assets": {
            "name": "Activos Eventuales",
            "entry_type": "assets",
          },
          "static-pages": {
            "name": "Páginas estaticas",
            "entry_type": "static-pages",
          },
          "jobs": {
            "name": "Plazas Disponibles",
            "entry_type": "jobs",
          },
          "promotions": {
            "name": "Promociones",
            "entry_type": "promotions",
          },
          "products": {
            "name": "Productos",
            "entry_type": "products",
          },
          "projects": {
            "name": "Proyectos Inmobiliarios",
            "entry_type": "projects",
          },
        },
        pageTitle: "",
        name: "",
        entry_type: "",
        notificationSettings: [],
      };
    },

    mounted() {
      this.$vs.loading();
      this.getTitlePage()
      this.getNotificationSettings()
          .then(() => {
            this.$vs.loading.close();
          });
    },

    methods: {
      getTitlePage() {
        let entry_type = this.$route.params["entry_type"]
        let val = this.entryTypeList[entry_type]
        this.pageTitle = 'Destinatarios de ' + val.name
        this.name = val.name
        this.entry_type = val.entry_type
      },

      dateFromTimestamp(timestamp) {
        let date = new Date(timestamp * 1000)
        return date.toLocaleString()
      },

      async getNotificationSettings(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await NOTIFICATION_SETTINGS_CLIENT.all(this.entry_type)
            .then(response => {
              this.notificationSettings = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async deleteNotificationSettings(id) {
        let entry_type = this.entry_type;

        if (entry_type !== null && entry_type !== undefined && entry_type !== "") {
          this.$vs.loading();
          await NOTIFICATION_SETTINGS_CLIENT.delete(entry_type, id)
              .then(response => {
                notifier.notification("deleted");
                this.getNotificationSettings();
              })
              .catch(error => {
                notifier.alertMessage("error");
              });

          this.$vs.loading.close();
        }
      },

      confirmDelete(notificationSettings) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteNotificationSettings(notificationSettings.id);
          }
        });
      },

      createNotificationSettings() {
        let routeName = `notification-settings-create-${this.entry_type}`
        this.$router.push({name: routeName, params: {entry_type: this.entry_type}});
      },

      updateNotificationSettings(id) {
        let routeName = `notification-settings-update-${this.entry_type}`
        this.$router.push({name: routeName, params: {entry_type: this.entry_type, id: id}});
      },
    },
  };
</script>


<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>