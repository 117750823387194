import axios from 'axios';


export default class NotificationSettingsClient {

  async create(data, entry_type) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}`;

    return axios({
      url: url,
      method: 'post',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async retrieve(entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/${id}`;

    return axios({
      url: url,
      method: 'get',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async update(data, entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/${id}`;

    return axios({
      url: url,
      method: 'put',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async delete(entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/${id}`;

    return axios({
      url: url,
      method: 'delete',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }


  async all(entry_type) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}`;

    return axios({
      url: url,
      method: 'get',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async create_scheduled_report(data, entry_type) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/scheduled-reports`;

    return axios({
      url: url,
      method: 'post',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async retrieve_scheduled_report(entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/scheduled-reports/${id}`;

    return axios({
      url: url,
      method: 'get',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }


  async update_scheduled_report(data, entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/scheduled-reports/${id}`;

    return axios({
      url: url,
      method: 'put',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async delete_scheduled_report(entry_type, id) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/scheduled-reports/${id}`;

    return axios({
      url: url,
      method: 'delete',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }

  async all_scheduled_reports(entry_type) {
    let url = `${process.env.VUE_APP_NOTIFICATIONS_RULES_V1_BASE_URL}/${entry_type}/scheduled-reports`;

    return axios({
      url: url,
      method: 'get',
      headers: {
        'x-api-key': process.env.VUE_APP_API_KEY,
      },
    });
  }
}